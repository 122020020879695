import schttp from 'public/src/services/schttp'
const newPosKeys = [
  'SearchSuggestNew',
  'PCAlltabCategory',
  'SuggestUI',
  'searchwordstyle',
  'newpresearch',
  'newpresearchlenovo',
  'newpresearchicon',
  'newsearch'
]

const posKeys = [
  'SearchSuggestwordFeedback',
  'RelatedSearchNew'
]

// 获取预搜页实验
const fetchPresearchAbt = () => {
  return  schttp({
    url: '/abt/merge/get_abt_by_poskey',
    params: {
      posKeys: [...newPosKeys, ...posKeys].join()
    },
    useBffApi: true
  })
}
export default fetchPresearchAbt
