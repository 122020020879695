import schttp from 'public/src/services/schttp'
const language = [
  'SHEIN_KEY_PC_20094',
  'SHEIN_KEY_PC_15867',
  'SHEIN_KEY_PC_25786',
  'SHEIN_KEY_PC_19920',
  'SHEIN_KEY_PC_16957',
  'SHEIN_KEY_PC_16956',
  'SHEIN_KEY_PC_28049',
  'SHEIN_KEY_PC_30767'
]
// 获取预搜页多语言
const fetchPresearchLang = () => {
  return  schttp({
    url: '/system/configs/multi_language',
    method: 'POST',
    data: {
      languageKeys: language,
    },
    useBffApi: true
  })
}

export default fetchPresearchLang
